<template>
    <div class="mapHolder" ref="mapHolder"></div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import { toRaw } from '@vue/reactivity';


    export default {
        data() {
            return {
                directionsService: null,
                directionsRenderer: null,
                map: null,
                timeout: null,
                googleServices: null,
                myMap: {
                    map: null,
                    google: null
                }
            }
        },
        mounted() {

            // set loads
            const loader = new Loader({
                apiKey: "AIzaSyDLxJWw58qz7U2DCEpEW71sFvQRQH8mURQ",
                version: 'beta',
                libraries: ["routes", "marker"],
                
            });

            // map options
            const mapOptions = {
                center: {
                    lat: 39.77746842075953, 
                    lng: -101.43786171303788,
                },
                zoom: 3,
                disableDefaultUI: true,
                mapId: 'a237294c3cd03ecf'
            };
            
            // google maps DOM holder
            let gMaps = this.$refs['mapHolder'];

            let that = this;

            // run loader
            loader
            .load()
            .then((google) => {
                that.directionsService = new google.maps.DirectionsService();
                that.directionsRenderer = new google.maps.DirectionsRenderer();
                let map = new google.maps.Map(gMaps, mapOptions);
                that.map = map;
                that.directionsRenderer.setMap(that.map);

                that.myMap.map = that.map;
                that.myMap.google = google;
                that.$emit('loaded', toRaw(that.myMap));

            })
            .catch(e => {
                // do something
            });

        }
    }
</script>

<style lang="scss" scoped>

.mapHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>